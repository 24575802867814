<template>
	<v-combobox v-bind="$attrs" :item-value="itemValue" :label="labelText" v-on="$listeners">
		<!-- Pass on all named slots -->
		<slot v-for="slot in Object.keys($slots)" :v-slot="slot" :name="slot"></slot>
		<!-- Pass on all scoped slots -->
		<template v-for="slot in Object.keys($scopedSlots)" v-slot:[slot]="scope"><slot :name="slot" v-bind="scope"></slot></template>
	</v-combobox>
</template>

<script>
import Validator from '@/mixins/Validator'

/**
 * @displayName w-combobox
 *
 * @since 0.3.2
 */
export default {
	name: 'WCombobox',
	mixins: [Validator],
	props: {
		itemValue: {
			required: false,
			type: String,
			default: 'id'
		},
		label: {
			required: false,
			type: String,
			default: ''
		},
		required: {
			required: false,
			type: Boolean,
			default: false
		}
	},
	computed: {
		inputRules: function () {
			let result = []
			if (this.required) {
				result.push(this.rules.required)
			}
			return result
		},
		labelText: function () {
			if (this.label) {
				if (this.required) {
					return this.label + '*'
				}
				return this.label
			}
			return null
		}
	}
}
</script>
